// import { navigate } from "vike/client/router"

const router = {
	linkActiveClass: "is-active",
	scrollBehavior: () => ({ y: 0 }),
	push: function (params, dontReload = false) {
		if (!import.meta.env.SSR) {
			// navigate(url)
			if (!dontReload) {
				window.location.href = this.assembleUrl(params)
			} else {
				window.history.pushState({ "pageTitle": document.head.title }, "", this.assembleUrl(params));
			}
		}
	},
	open: function (params) {
		if (!import.meta.env.SSR) {
			// alert(window.location.origin + this.assembleUrl(params))
			let url = window.location.origin + this.assembleUrl(params)
			console.log(url)
			window.open(url, '_blank')
		}
	},
	go: function (steps) {
		if (!import.meta.env.SSR) {
			window.history.go(steps)
		}
	},
	route: {
		// query: [],//(!import.meta.env.SSR ? new URLSearchParams(window.location.search) : []),
		query: (!import.meta.env.SSR ? new URLSearchParams(window.location.search) : []),
		params: [],
		path: '',
		url: '',
	},
	assembleUrl: function (params) {
		let url = ''
		if (typeof params == 'string') {
			url = params
		} else if (typeof params == 'object') {
			if (params.path) {
				url = params.path
			}
			if (params.query) {
				for (const fieldName in params.query) {
					if (params.query.hasOwnProperty(fieldName)) {
						url += (!url.match(/\?/) ? '?' : '&')
							+ fieldName + '='
							+ encodeURIComponent(params.query[fieldName])
					}
				}
			}
			if (params.params) {
				for (const fieldName in params.params) {
					url = url.replace(':' + fieldName, encodeURIComponent(params.params[fieldName]))
				}
			}
		}
		return url
	},
	removeQueryParams: function () {
		const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname
		window.history.replaceState({ path: newUrl }, '', newUrl)
	},
}

export default async (app, pageContext) => {
	router.route.params = pageContext.routeParams
	router.route.query = pageContext.urlParsed.search
	router.route.path = pageContext.urlPathname
	router.route.url = pageContext.urlOriginal

	app.router = router
	app.$router = router
	app.$route = router.route
	app.config.globalProperties.$route = router.route
	app.config.globalProperties.$router = router
}
